$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;

$body-color: #262525;
$body-bg: #333;

$headings-color: #000;
$headings-font-weight: bold;
$headings-font-family: $font-family-base;

$primary: #333;

$link-color: #64B6B1;
$link-hover-color: #676767;

$navbar-padding-x: 0;
$navbar-padding-y: 4px;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 4px;

$nav-tabs-link-active-bg: #e6e6e6;

$navbar-light-color:                #fff;
$navbar-light-hover-color:          #000;
$navbar-light-active-color:         #000;
$navbar-light-disabled-color:       lighten(#000, 5%);

$navbar-link-bg: #000;
$navbar-link-hover-bg: #e6e6e6;
$navbar-link-active-bg: #e6e6e6;
$navbar-item-separation-border-color: #fff;

$hamburger-color: #000;

$story-headings-color: $headings-color;
$story-headings-font: $font-family-base;

@import "../../fonts/lato";
@import "../../fonts/source-sans-pro";
@import "../../fonts/oswald";
@import "../../magazine";

@include media-breakpoint-up(md) {
  .brand-logo img {
    height: 80px;
  }
}

.navbar-expand>.container {
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
}

.navbar {
  border-top: 2px dotted #000;

  @include media-breakpoint-down(sm) {
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      border-bottom: 2px dotted #000;
    }
  }

  @include media-breakpoint-up(md) {
    border-bottom: 2px dotted #000;

    .navbar-nav > .nav-item + .nav-item {
      padding-left: 6px;
    }
  }

  .navbar-nav .nav-link {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      @include font-size(1.125rem);
  }

  .nav-item.tag-raetsel-des-tages .nav-link {
    @include hover-focus {
      background-color: #51B5D9;
    }
  }
}

.footer-body {
  border-top: 1px solid #e9e9e9;
}

.article-head p {
  color: #777;
}

.article-media-source a {
  @include plain-hover-focus {
    text-decoration: underline !important;
  }
}

.sidebar .card.card-bordered {
  border: 1px solid #000;
}

.article-story {
  h3, h4 {
    @include font-size(1.5rem);
  }
}
